.wrapper {
    overflow-x: scroll;
    height: calc(100vh - 120px);
    padding: 16px;
}

.days {
    display: grid;
    grid-template-columns: repeat(24, 4.16%);
    padding: 16px 16px 0 116px;
}

.classroomListItemContainer {
    position: relative;
}

.classroomListItem {
    padding-left: 16px;
    display: flex;
}

.classroomListItemMark {
    position: absolute;
    top: 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
}
