.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100vw;
}

.list li {
    padding: 16px;
    border: 1px solid transparent;
}

.list li a {
    padding-left: 16px;
    color: #00a6ff;
    text-decoration: none;
}

.list li:nth-child(odd) {
    background-color: rgba(0, 166, 255, .05);
}

.list li:hover {
    border: 1px solid #00a6ff;
}

.settings {
    margin-top: 32px;
    margin-left: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.unverified {
    position: relative;
    top: -8px;
    left: 4px;
    color: #fff;
    background-color: #ff003b;
    border-radius: 8px;
    padding: 1px 4px;
    font-size: .7rem;
}