@media (min-width: 320px) {
  /*sidebar collapsed?*/
  .true {
    width: 100vw;
    height: 40px;
  }
  /*sidebar collapsed?*/
  .false {
    width: 100vw;
    height: 100vh;
  }
  .navigation {
    background-color: #2e287c;
    transition: all 0.3s ease-in-out;
    /*box-shadow: 6px 0px 16px -10px rgba(0, 0, 0, 0.75);*/
    overflow: hidden;
  }
  .navigation .logoWrapper {
    display: none;
  }
  .menuIcon {
    position: absolute;
    display: block;
    left: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
  }
  .currentPageName {
    display: block;
    color: #fff;
    font-family: "Roboto Thin";
    font-size: 1.5rem;
    padding: 0;
    margin: 4px 0 16px 0;
    font-weight: normal;
    text-align: center;
  }
}
@media (min-width: 1024px) {
  /*sidebar collapsed?*/
  .true {
    width: 50px;
  }
  /*sidebar collapsed?*/
  .false {
    width: 240px;
  }
  .navigation {
    background-color: #2e287c;
    height: 100vh;
    transition: all 0.3s ease-in-out;
    /*box-shadow: 6px 0px 16px -10px rgba(0, 0, 0, 0.75);*/
    color: #fff;
    overflow: hidden;
  }
  .navigation .logoWrapper {
    display: block;
  }
  .menuIcon {
    display: none;
  }
  .currentPageName {
    display: none;
  }
}

.navigation ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.navigation ul li {
  position: relative;
  padding-left: 13px;
  margin-bottom: 8px;
}

.icon {
  width: 24px;
  height: 24px;
  padding-right: 16px;
  margin: 0;
}

.settings {
  filter: invert(1);
}

.link:hover {
  background: rgba(43, 93, 255, .2);
}

.link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  font-family: "Roboto Thin";
  font-size: 1.5rem;
  padding: 8px 8px 8px 14px;
  margin-left: -14px;
  transition: all .1s ease-in-out;
  white-space: nowrap;
}

.linkActive {
  background-color: #2b5dff;
}

.linkActive:hover {
  background-color: #2b5dff;
}

.alert {
  position: absolute;
  z-index: 100;
  left: 28px;
  top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-family: "Arial Black";
  line-height: .5;
  background-color: #ff003b;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 20px;
}

.finishButtonWrapper {
  position: absolute;
  bottom: 16px;
  left: 16px;
}

.finishButtonWrapperCollapsed {
  position: absolute;
  bottom: 16px;
  left: 6px;
  width: 60px;
}

.finishButtonWrapper svg, .finishButtonWrapperCollapsed svg {
  width: 24px;
  height: 24px;
}

.dispatcherInactive {
  position: fixed;
  z-index: 9999999;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.dayFinishedTitle {
  background-color: rgba(255, 0, 0, .3);
  width: 100vw;
  text-align: center;
  padding: 16px 0;
}