.userType {
    color: #fff;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
}

.centerText {
    text-align: center;
}

.unverified {
    color: #f91354;
    background: #f9135411;
    border-radius: 6px;
    padding: 6px;
    margin: 0 8px;
    border: 1px solid #f91354;
}

.sanctioned {
    color: #f91354;
    border-radius: 6px;
    padding: 6px;
    margin: 0 8px;
}

.cancelSanctionFooter {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 16px;
}