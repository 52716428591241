.wrapper {
    width: 100%;
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2e287c;
    color: #fff;
}

.wrapper p {

}

.loader {
    width: 60px;
    height: 60px;
    border-radius: 16px;
}