@media (min-width: 320px) {
  .logo::before {
    display: block;
    content: "";
    width: 4rem;
    height: 4rem;
    border: 5px solid #fff;
    border-radius: 15px;
    position: absolute;
    left: -0.66rem;
    top: -0.7rem;
    z-index: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }
  .logoTitle {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 2.4rem;
    line-height: 2.1rem;
    font-family: "Roboto Medium";
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation-delay: 0.3s;
    transition: all 0.3s ease-in-out;
  }

  .logoDescription {
    color: #fff;
    text-transform: uppercase;
    font-size: 0.66rem;
    font-family: "Roboto Thin";
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation-delay: 0.3s;
  }
  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
}

@media (min-width: 1024px) {
  .logo::before {
    display: block;
    content: "";
    width: 6rem;
    height: 6rem;
    border: 8px solid #fff;
    border-radius: 25px;
    position: absolute;
    left: -1rem;
    top: -0.9rem;
    z-index: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  }
  .logoTitle {
    color: #fff;
    text-transform: uppercase;
    font-size: 4rem;
    line-height: 3.5rem;
    font-family: "Roboto Medium";
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation-delay: 0.3s;
    transition: all 0.3s ease-in-out;
  }

  .logoDescription {
    color: #fff;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-family: "Roboto Thin";
    font-weight: normal;
    margin: 0;
    padding: 0;
    position: relative;
    left: 0;
    animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation-delay: 0.3s;
  }
  .logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
}

.logo,
.smallLogo {
  display: flex;
  justify-content: right;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  user-select: none;
  cursor: pointer;
}

.smallLogo::before {
  display: block;
  content: "";
  width: 2rem;
  height: 2rem;
  border: 2px solid #fff;
  border-radius: 7px;
  position: absolute;
  left: -0.22rem;
  top: -0.35rem;
  z-index: 0;
  animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.smallLogoTitle {
  display: inline-block;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-family: "Roboto Medium";
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: relative;
  left: 0;
  animation: animate-position 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation-delay: 0.3s;
  transition: all 0.3s ease-in-out;
}

.logoText {
  background-color: #2e287c;
  z-index: 1;
  padding: 2px;
  overflow: hidden;
}

@keyframes animate-position {
  0% {
    transform: translateX(-18%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
