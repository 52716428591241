.wrapper {
    list-style-type: none;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
}

.wrapper li {
    padding: 16px 8px;
    cursor: pointer;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.selected {
    color: #fff;
    background-color: #00a6ff;
}
