.wrapper {
    width: calc(100% - 100px);
    position: relative;
    height: 20px;
    margin-left: 100px;
}

.unit {
    position: absolute;
    border-radius: 4px;
    border: 2px solid #fff;
    box-sizing: border-box;
    color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    font-size: 13px;
    z-index: 1;
    padding: 3px;
}

.unit:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    border: 2px solid #00000033;
    color: #000;
    cursor: pointer;
}

.unit:hover > span {
    background-color: #ffffff33;
    position: absolute;
    border-radius: 4px;
}

.dates {
    position: absolute;
    list-style-type: none;
    margin: 0 0 0 -100px;
    padding: 0;
    width: calc(100% + 100px);
}

.dates li {
    height: 20px;
    border-bottom: 1px solid #00000011;
    width: 100%;
    box-sizing: border-box;
}
