.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.container input, .container textarea {
    box-sizing: border-box;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #2e287c;
    font-family: Roboto;
    font-size: 1rem;
    width: 100%;
}

.container input:first-child {
    width: available;
}

input[type='checkbox'] {
    width: 16px;
}

label {
    display: grid;
    grid-template-columns: 24% auto;
    align-items: center;
    margin-bottom: 8px;
}

.instrumentsCheckbox {
    font-size: .7rem;
    color: #656565;
    display: flex;
    align-items: center;
}

.addInstrumentIcon {
    margin-right: 10px;
    opacity: .3;
    width: 12px;
}

.extraPhoneWrapper {
    position: relative;
}

.extraPhoneWrapper img {
    position: absolute;
    width: 20px;
    right: 8px;
    top: 10px;
    cursor: pointer;
    opacity: .5;
}

.extraPhoneWrapper img:hover {
    opacity: 1;
}