.customInput, .customInput:-webkit-autofill {
    height: 2.5rem;
    background-color: transparent;
    border-radius: 8px;
    font-family: "Roboto Thin";
    font-size: 1rem;
    transition: all .1s ease-in-out;
    padding: 0 0 0 16px;
}

.customInputLight {
    border: 1px solid #fff;
    color: #fff;
}

.customInputDark {
    border: 1px solid #ccc;
    color: #2e287c;
}

.customInput:hover, .customInput:-webkit-autofill:hover {
    border: 1px solid #5692ff;
}

.customInput:focus, .customInput:-webkit-autofill:focus {
    outline: none;
    border: 1px solid #5692ff;
    box-shadow: 0px 0px 10px 0px rgba(0,60,255,0.44);
}

.customInput::placeholder {
    color: rgba(255, 255, 255, .26);
    font-style: italic;
}

.customLabel {
    font-family: "Roboto Light";
    font-size: 1rem;
    margin: 8px 0;
    position: relative;
}

.light {
    color: #fff;
}

.dark {
    color: #656565;
}

.eye {
    position: absolute;
    right: 16px;
    top: 8px;
}