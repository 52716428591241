.caviar {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin: 16px 14px 0 16px;
  font-family: "Roboto Light";
  background-color: #eeeeee;
}

.caviar::after {
  content: "";
  flex: auto;
}

.caviar li {
  min-width: 1rem;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 4px;
  margin: 0 2px 2px 0;
  transition: all 0.2s ease-in-out;
}

.caviar li:hover {
  background-color: #2b5dff !important;
  color: #fff;
}

.caviar li:active {
  background-color: #fff !important;
  color: #2b5dff;
}
