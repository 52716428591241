.wrapper {
    display: flex;
    flex-direction: column;
    margin: 20px;
}

.row {
}

.className {
    /*position: absolute;*/
}

.row span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
}

.scheduleHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    padding-bottom: 4px;
}

.scheduleHeader:after {
    content: '';
    display: block;
    left: 75px;
    right: 20px;
    top: 97px;
    height: 6px;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0, 0, 0, .08) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
}

.space {
    width: 60px
}

.timeline {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(15, 6.6%);
}

.timeline span {
    position: relative;
    margin-left: 2px;
}

.timeline span:before {
    content: '';
    display: block;
    position: absolute;
    width: 3px;
    height: 100vh;
    border-left: 1px solid rgba(204, 204, 204, .3);
}

.scheduleUnits {
    padding-top: 4px;
    height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: scroll;
}

.scheduleUnits::-webkit-scrollbar {
    display: none;
}
