@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Light';
  src: local('Roboto-Light'), url('./assets/fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Medium';
  src: local('Roboto-Medium'), url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto Thin';
  src: local('Roboto Thin'), url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, Arial, Calibri;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

code {
  font-family: Roboto, Arial, Calibri;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888833;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}