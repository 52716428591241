@media (min-width: 320px) {
    .occupantCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 8px 8px 32px;
        background-color: #fcfcfc;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        position: relative;
    }
}

@media (min-width: 1024px) {
    .occupantCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 8px 32px 8px 32px;
        background-color: #fcfcfc;
        border-radius: 8px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .occupantCard:first-child {
        margin-right: 8px;
    }
}

.cards {
    display: flex;
    justify-content: space-between;
}

.occupantType {
    font-family: "Roboto Light";
    font-size: .8rem;
    margin: 0;
    padding: 2px 4px;
    border-radius: 3px;
    display: inline-block;
}

.occupantCard:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
    cursor: pointer;
}

.icon {
    position: absolute;
    top: 20px;
    left: 8px;
    width: 16px;
    height: 16px;
    background-image: url("./../../../assets/images/profile.svg");
    background-repeat: no-repeat;
    filter: invert(27%) saturate(2878%) brightness(104%) contrast(97%);
}

.occupantName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.occupantName p {
    margin: 0;
    padding: 0;
}

.occupiedUntil {
    border-top: 1px solid #eee;
    margin: 8px -8px 0 -32px;
    padding-top: 8px;
    text-align: center;
    font-family: "Roboto Light";
    color: #656565;
    font-size: .9rem;
}

.state {
    margin: 0;
    padding: 5px 0 10px;
    font-family: "Roboto Light";
}

.keyHolderStatus {
    overflow: hidden;
    position: relative;
}

.keyHolderStatus::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 200px;
    height: 100%;
    background-image: url("../../../assets/images/key.png");
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: right center;
    /*filter: invert(1);*/
}

.controlButtons {
    display: flex;
    justify-content: flex-end;
    margin-right: -25px;
    margin-left: -25px;
}

.controlButtons button {
    margin-left: 8px;
}
