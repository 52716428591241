@media (max-width: 1024px) {
    .wrapper {
        justify-content: center;
        padding: 16px;
    }
}

@media (min-width: 1024px) {
    .wrapper {
        justify-content: center;
        padding: 16px 25%;
    }
}

.userSearch button {
    margin-top: 16px;
}

.checkboxInstrument {
    margin: 16px 0;
    display: block;
}

.sanctions {
    color: #f91354;
    font-weight: 600;
    font-size: 14px;
}