.tooltip {
  position: absolute;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  padding: 4px 8px;
  border: 1px solid  #eee;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 200;
  top: 60px;
}