.container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #2e287c;
  justify-content: center;
}

@media (max-width: 1024px) {
  .loginContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 95vw;
  }
}

@media (min-width: 1024px) {
  .loginContainer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 400px
  }
}

.loginTip {
  text-align: center;
  color: #fff;
  border-radius: 8px;
  padding: 8px 8px;
  font-family: "Roboto Thin";
  font-weight: normal;
  font-size: 1rem;
  margin: 16px 0 32px 0;
  animation-duration: 1.8s;
  background: linear-gradient(-45deg, #2e287c, #f91354, #2b5dff, #2e287c);
  background-size: 600% 600%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginButtons {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
}

.loginFooter {
  text-align: center;
}

.loginFooter a {
  color: #fff;
  font-family: "Roboto Thin";
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.loginFooter p {
  margin: 0;
  padding: 8px 0;
  font-family: "Roboto Thin";
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.2);
}