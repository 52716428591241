.wrapper {
    width: 100vw;
    height: 100vh;
}

.container {
    display: grid;
    grid-template-columns: 50% 1fr;
    height: 100vh;
}

.header {
    display: grid;
    grid-template-columns: 50% 1fr auto;
    width: 100%;
    padding-right: 20px;
    align-items: center;
}

.header h1 {
    font-family: Roboto;
    font-weight: 900;
    text-transform: uppercase;
    /*animation: swing-in-top-fwd 15s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;*/
    animation-iteration-count: infinite;
}

.generalQueue, .waitingApprove {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.generalQueue li {
    box-sizing: border-box;
    margin: 16px;
    background-color: #eee;
    height: calc((100vh - 240px) / 10);
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-size: 24px;
    text-transform: uppercase;
    color: #4e4e4e;
    animation: swing-in-top-fwd-fast .5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}

.generalQueue li span:first-child {
    width: 40px;
    text-align: center;
}

.waitingApprove li {
    margin: 16px 16px 16px 0;
    background-color: #4bfd63;
    height: calc((100vh - 240px) / 10);
    border-radius: 8px;
    font-size: 24px;
    text-transform: uppercase;
    color: #1d5d25;
    font-weight: 900;
    display: flex;
    align-items: center;
    animation: swing-in-top-fwd-fast .5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
    overflow: hidden;
    text-overflow: ellipsis;
}

.waitingApprove li .classroomName {
    background-color: #ffffff77;
    padding: 5px 10px;
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
    border-radius: 8px 0 0 8px;
    margin-right: 16px;
}

.fullName {
    font-weight: 900;
    padding-left: 32px;
}

.rightPart {
    height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info {
    background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%);
    padding: 0 20px 10px 20px;
    font-weight: 900;
    text-transform: uppercase;
}

.info p {
    padding: 10px 0 0 0;
    margin: 0;
    font-size: 25px;
    /*animation: swing-in-top-fwd 15s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;*/
    animation-iteration-count: infinite;
    text-align: right;
}

.info p span {
    border: 1px solid #c1bfbf;
    border-radius: 8px;
    padding: 0 5px;
    margin-left: 5px;
    min-width: 30px;
    max-width: fit-content;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.hiddenLogout {
    position: absolute;
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 5px 10px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 16px;
    left: calc(100vw / 2 - 50px);
    top: calc(100vh / 2 - 100px);
    opacity: 0;
}

.hiddenLogout:hover {
    opacity: 1;
}

.noQueue {
    padding-left: 16px;
    color: #ccc;
}

@keyframes swing-in-top-fwd {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    5% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}

@keyframes swing-in-top-fwd-fast {
    0% {
        transform: rotateX(-100deg);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        transform: rotateX(0deg);
        transform-origin: top;
        opacity: 1;
    }
}
