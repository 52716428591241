.list {
    list-style-type: none;
    display: flex;
    margin: 0 0 8px 0;
    padding: 0;
}

.list li {
    margin-right: 4px;
}

.unit {
    border: none;
    border-radius: 6px;
    height: 30px;
    cursor: pointer;
}

.unit:hover {
    background-color: #cccccc;
}
