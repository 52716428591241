.wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 80px);
  padding: 16px;
}

.list {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.listHeader {
  position: fixed;
  margin-top: -32px;
  width: calc(100% - 90px);
  background: #fff !important;
  box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.2);
}

.list li:nth-child(2) {
  padding-top: 20px;
}

.list li {
  display: grid;
  grid-template-columns: 1fr 6fr 2fr 40px 40px;
  align-items: center;
}

.list li:nth-child(odd) {
  background-color: rgba(0, 166, 255, .05);
}

.list li p:first-child {
  margin-left: 16px;
}

.list li > * {
  margin-right: 8px;
}

.alignText {
  text-align: center;
}