.list > div > span:first-child {
    display: inline-block;
    min-width: 150px;
}

.list > div > span:nth-child(2) {
    display: inline-block;
    color: #656565;
}

.list div {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #eee;
    padding: 8px 8px 16px 8px;
}

.verification {
    background: rgba(255, 0, 59, .2);
    border: 2px solid #ff003b;
    border-radius: 8px;
    margin: 16px;
    padding: 16px;
    color: #ff003b;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.verification h2 {
    margin: 0;
}

.verification p {
    padding-top: 16px;
    margin: 0;
}

.sanctioned {
    color: #ff003b;
    padding-bottom: 32px;
    text-align: center;
}