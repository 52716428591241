.controlButtons {
    position: absolute;
    right: 16px;
    display: grid;
    grid-template-columns: 24px 24px;
    grid-gap: 8px;
}

.controlIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.addIcon {
    width: 12px;
    height: 12px;
    filter: invert();
    margin-left: 10px;
    margin-right: 2px;
    margin-bottom: -2px;
}

.unitData div {
    display: grid;
    grid-template-columns: 100px 1fr;
    border-bottom: 1px solid #00000011;
    padding: 8px 0;
    white-space: nowrap;
}

.removeFooter {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 8px;
}

.editWrapper {
    display: grid;
    grid-template-rows: repeat(6, 45px);
}

.editWrapper .input {
    height: 35px;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 0 6px 0 10px;
}

.errorMessage {
    color: #f91354;
    padding: 16px 0 8px 0;
    margin: 0;
    line-height: 24px;
}

.buttons {
    display: grid;
    justify-content: end;
    grid-template-columns: 100px 100px;
    grid-gap: 8px;
    padding-top: 16px;
}

.subList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.subListItem {
    padding: 4px 8px;
    border: 1px solid #cccccc55;
    border-radius: 4px;
    margin: 0 0 8px 0;
    width: fit-content;
    white-space: nowrap;
}

.dataHints {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dataHints div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 8px;
}

.dataHints div button {
    height: 30px;
}
