.container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 100px);
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 90vw;
}

.list li:nth-child(2) {
    margin-top: 40px;
}

.headerRow {
    box-sizing: border-box;
    position: fixed;
    z-index: 100;
    background-color: #fff;
    display: grid;
    grid-gap: 16px;
    align-items: center;
    padding: 21px 10px 8px 10px;
    margin: -16px 0 0 0;
    width: 90vw;
    box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.2);
}

.headerRow span {
    cursor: pointer;
}

.headerRow span:not(.headerRow span:nth-child(2)){
    text-align: center;
}

.row {
    display: grid;
    grid-gap: 16px;
    align-items: center;
    padding: 10px 10px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #fff;
    transition:  all .1s linear;
}

.row:nth-child(odd) {
    background-color: rgba(0, 166, 255, .05);
}

.row:hover {
    border-top: 1px solid #00a6ff;
    border-bottom: 1px solid #00a6ff;
    color: #00a6ff;
}

.emptyResult {
    text-align: center;
}