@media (max-width: 1024px) {
  .selectClassroomsFilter {
    margin-left: 0;
    border-radius: 8px;
    border: none;
    background: transparent;
    padding: 8px;
    color: #fff;
    font-family: "Roboto Light";
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }

  .selectClassroomsFilter:focus,
  .selectClassroomsFilter:hover {
    border: none;
    outline: none;
  }
}

@media (min-width: 1024px) {
  .selectClassroomsFilter {
    margin-left: 8px;
    border-radius: 8px;
    border: 1px solid #fff;
    background: transparent;
    padding: 8px;
    color: #fff;
    font-family: "Roboto Light";
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
  }

  .selectClassroomsFilter:focus,
  .selectClassroomsFilter:hover {
    border: 1px solid #5692ff;
    box-shadow: 0px 0px 10px 0px rgba(0, 60, 255, 0.44);
    outline: none;
  }
}

.classroomsPage {
  background-color: #eeeeee;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.classroomsList {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  margin: 16px;
  padding: 0;
}

.selectClassroomsFilter option {
  color: #2e287c;
}

.selectClassroomsFilter option:checked {
  background-color: #eee;
}

.updateDataModal {
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, .7);
}

.popup {
  border-radius: 16px;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.07);
  width: 300px;
  height: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.crashModeWrapper {
  margin: 16px;
  padding: 16px;
  border-radius: 6px;
  border: 2px solid #f91354;
  background-color: #f9135433;
  color: #f91354;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.crashModeWrapper h1 {
  margin: 0;
  padding: 0 0 10px 0;
  text-align: center;
}

.crashModeWrapper p {
  padding: 0 0 10px 0;
  margin: 0;
  text-align: center;
}