.classroomsListItem {
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  min-height: 102px;
}

.classroomsListItem:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}

.classroomsListItem:focus {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Roboto;
}

.special {
  position: absolute;
  width: 16px;
  right: 0;
  top: 0;
}

.footer {
  position: relative;
  width: 100%;
  display: grid;
  justify-content: end;
  grid-template-columns: 1fr auto;
}

.footer > button {
  margin-left: 16px;
  width: fit-content;
}

.footer div > button{
  margin-left: 8px;
}

.header h1 {
  font-family: "Roboto Medium";
  min-width: 2.4rem;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin: 0;
  font-weight: normal;
  font-size: 1.5rem;
  text-align: center;
}

.occupantInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 8px;
}

.occupantName {
  line-height: 1rem;
  font-size: 1.2rem;
  display: inline-block;
  margin: 0 0 4px 0;
  width: 185px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.occupantType {
  font-family: "Roboto Light";
  font-size: .8rem;
  margin: 0;
  padding: 1px 4px;
  border-radius: 3px;
}

.occupationInfo {
  min-height: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0 -8px;
  font-size: 1rem;
  font-family: "Roboto Light";
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
}

.occupationInfo p {
  padding: 0;
  margin: 8px 0;
}

.isDepartment {
  color: #ff3c00;
}

.reserved {
  background-color: #2b5dff;
  color: #fff;
}

.overdue {
  background-color: #f91354;
  color: #fff;
}

.disableButton {
  position: absolute;
  left: 16px;
}

.disableClassroomInput {
  display: grid;
  grid-template-columns: .4fr 1fr .2fr .2fr;
  grid-gap: 8px;
}

.disableClassroomInput input{
  height: 20px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #656565;
}

.disableClassroomInput span{
  width: 30%
}

.sanctions {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: 50px;
}

.sanctions label {
  white-space: nowrap;
  padding-right: 8px;
  color: #f91354;
}

.sanctions input {
  border: 2px solid #f91354 !important;
}

.confirmText {
  text-align: center;
  color: #484848;
}

.timeButtonActive {
  background: #00a6ff;
  color: #fff;
  border: none;
  padding: 4px;
  margin-right: 2px;
  cursor: pointer;
}

.timeButton {
  background: #ccc;
  color: #000;
  border: none;
  padding: 4px;
  margin-right: 2px;
  cursor: pointer;
}

.customTimeInput, .customTimeInputActive {
  display: inline-block;
  position: relative;
}

.customTimeInput input {
  padding: 4px 32px 4px 4px;
  border: none;
  background: #ccc;
}

.customTimeInputActive input {
  padding: 4px 32px 4px 4px;
  border: none;
  background: #00a6ff;
  color: #fff;
}

.customTimeInput span {
  position: absolute;
  right: 2px;
  top: 4px;
  font-size: 13px;
}

.customTimeInputActive span {
  position: absolute;
  right: 2px;
  top: 4px;
  font-size: 13px;
  color: #fff;
}

.disableOptions {
  padding-top: 16px;
}

.buttonsRow {
  padding-left: 24%;
  margin-bottom: 32px;
  margin-top: -4px;
}

.buttonsRow button {
  margin: 4px 4px 0 0;
  font-size: 12px;
  height: 24px;
}

.unlockIcon {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 14px;
  opacity: .2;
  cursor: pointer;
  transition: all .1s linear;
}

.unlockIcon:hover {
  opacity: .7;
}

.lockIcon {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 14px;
  cursor: pointer;
}